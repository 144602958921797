/* eslint-disable dot-notation */
/* eslint-disable import/prefer-default-export */

import { useVideo } from "@audacia-hq/shared/contexts";
import {
  faArrowUpRightFromSquare,
  faChevronDown,
  faCompress,
  faExpand,
  faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, {
  useEffect, useRef, useState,
} from "react";

import DevicePicker from "../form/DevicePicker";
import useNavigatorMediaDevices from "../hooks/useNavigatorMediaDevices";

interface Props {
  isFullscreen?: boolean;
  onFullscreenChange?: (fullscreenValue: boolean) => void;
  onExpand?: () => void;
}

const VideoRoomButtons: React.FC<Props> = ({ isFullscreen, onFullscreenChange, onExpand }) => {
  const {
    t, userSettings, updateUserSettings, pushTelemetryEvent, videoRoom, forceCamera,
  } = useVideo();
  const [showDeviceSettings, setShowDeviceSettings] = useState<boolean>(false);
  const { devices, states } = useNavigatorMediaDevices(["AUDIO", "VIDEO"], forceCamera ? undefined : ["VIDEO"]);
  const statesRef = useRef(states);

  // const [isFullscreen, setFullscreen] = useState<boolean>(false);

  const buttonClassName = "text-white w-10 h-10 rounded-full flex place-content-center items-center pointer-events-auto";

  const onDeviceChange = (mediaType: "AUDIO" | "VIDEO") => (deviceId: string) => {
    pushTelemetryEvent({
      event: "DEVICE_CHANGED",
      data: {
        origin: "VideoRoom",
        mediaType,
        deviceId,
        videoRoomUid: videoRoom.videoRoomUid,
        permissionState: statesRef.current["VIDEO"],
      },
    });
    if (mediaType === "AUDIO") {
      updateUserSettings({ audioDevice: deviceId });
    } else {
      updateUserSettings({ videoDevice: deviceId });
    }
  };

  useEffect(() => {
    statesRef.current = states;
    pushTelemetryEvent({
      event: "DEVICE_PERMISSIONS_CHANGED",
      data: {
        origin: "VideoRoom",
        videoRoomUid: videoRoom.videoRoomUid,
        states,
        devices,
      },
    });
  }, [states, devices]);

  return (
    <div className="w-full p-4 md:px-12 bg-slate-950 @container/videoButtons flex flex-col gap-4">
      <div className="flex gap-4">
        <button
          type="button"
          aria-label="Toggle microphone"
          onClick={() => updateUserSettings({ audioToggle: !userSettings.audioToggle })}
          data-pw="microphone_toggle"
        >
          <div
            className={`${buttonClassName}${userSettings.audioToggle ? " bg-white/[.33]" : " bg-red-600"}`}
          >
            <FontAwesomeIcon icon={userSettings.audioToggle ? faMicrophone : faMicrophoneSlash} />
          </div>
        </button>

        <button
          type="button"
          aria-label="Toggle camera"
          onClick={(!forceCamera && !userSettings.videoDevice) ? () => ({})
            : () => updateUserSettings({ videoToggle: !userSettings.videoToggle })}
          data-pw="camera_toggle"
        >
          <div
            className={clsx(
              buttonClassName,
              (() => {
                if (!forceCamera && !userSettings.videoDevice) return "bg-white/60 !text-white/60 !cursor-not-allowed";
                if (userSettings.videoToggle) return "bg-white/[.33]";
                return "bg-red-600";
              })(),
            )}
          >
            <FontAwesomeIcon icon={!userSettings.videoToggle || (!forceCamera && !userSettings.videoDevice) ? faVideoSlash : faVideo} />
          </div>
        </button>

        {onFullscreenChange && (
          <button
            type="button"
            aria-label="Toggle fullscreen"
            onClick={() => onFullscreenChange(!isFullscreen)}
            data-pw="fullscreen_toggle"
            className="mobile_landscape:hidden"
          >
            <div
              className={`bg-white/[.33] ${buttonClassName}`}
            >
              <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} />
            </div>
          </button>
        )}

        <div className="flex flex-row-reverse grow">
          <button
            type="button"
            className="@lg:hidden"
            aria-label="Toggle devices"
            onClick={() => setShowDeviceSettings((prev) => !prev)}
            data-pw="devices_toggle"
          >
            <div
              className={buttonClassName}
            >
              <FontAwesomeIcon
                icon={faChevronDown}
                className={clsx("transition-all ease-in-out duration-300", showDeviceSettings ? "rotate-180 " : "")}
              />
            </div>
          </button>

          <div className="grid-cols-2 gap-4 hidden @lg:grid shrink max-w-md justify-stretch grow">
            <DevicePicker
              mediaType="AUDIO"
              initialValue={userSettings?.audioDevice}
              devices={devices.AUDIO}
              onChange={onDeviceChange("AUDIO")}
              className=""
            />
            <DevicePicker
              mediaType="VIDEO"
              initialValue={userSettings?.videoDevice}
              nullLabel={forceCamera ? undefined : t("video.noCamera")}
              devices={devices.VIDEO}
              onChange={onDeviceChange("VIDEO")}
              className=""
            />
          </div>

        </div>

        {onExpand && (
          <button
            type="button"
            aria-label="Expand"
            onClick={onExpand}
            data-pw="expand_video"
          >
            <div
              className={buttonClassName}
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="transform -scale-x-100" />
            </div>
          </button>
        )}
      </div>

      { showDeviceSettings && (
        <div className={clsx(
          "grid grid-cols-1 @sm:grid-cols-2 @lg:hidden gap-4 justify-stretch ",
        // showDeviceSettings ? "h-auto" : "h-0",
        )}
        >
          <DevicePicker
            mediaType="AUDIO"
            initialValue={userSettings?.audioDevice}
            devices={devices.AUDIO}
            onChange={onDeviceChange("AUDIO")}
            className=""
          />
          <DevicePicker
            mediaType="VIDEO"
            initialValue={userSettings?.videoDevice}
            nullLabel={t("video.noCamera")}
            devices={devices.VIDEO}
            onChange={onDeviceChange("VIDEO")}
            className=""
          />
        </div>
      )}

    </div>
  );
};

export default VideoRoomButtons;
